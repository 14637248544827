





























import { Status } from "@/interfaces"
import api from "@/utils/api"
import { Component, Vue } from "vue-property-decorator"

@Component
export default class Statuses extends Vue {
  private statuses: Status[] = []

  private mounted() {
    this.getStatuses()
  }

  private saveStatus() {
    api.put('/admin/statuses', this.statuses)
      .then(() => {
        this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: 'Статус успешно сохранен' })
      })
      .catch((error) => {
        this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private async getStatuses() {
    const { data } = await api.get('/admin/statuses')

    this.statuses = data
  }
}
