







































import { Component, Vue } from "vue-property-decorator"
import { AxiosResponse } from "axios"

// Components
import QuoteCard from './cards/QuoteCard.vue'
import QuoteModal from '@/components/modals/QuoteModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { Quote } from "@/interfaces/quote"
// Utils
import api from "@/utils/api"

@Component({
  components: {
    QuoteCard,
    QuoteModal,
  }
})
export default class SectionQuote extends Vue {
  private isShowModal = false
  private quotes: Quote[] = []

  private sort () {
    const orderQuotes = this.quotes.map(item => item.id)
    api.patch('/admin/quotes/sort', { items: orderQuotes })
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Цитаты отсортированы' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private nextCard (index: number) {
    const currentQuote = this.quotes[index]
    if (this.quotes.length > index + 1) {
      this.$set(this.quotes, index, this.quotes[index + 1])
      this.$set(this.quotes, index + 1, currentQuote)
    }
    this.sort()
  }

  private prevCard (index: number) {
    const currentQuote = this.quotes[index]
    if (index > 0) {
      this.$set(this.quotes, index, this.quotes[index - 1])
      this.$set(this.quotes, index - 1, currentQuote)
    }
    this.sort()
  }

  private changeQuote (quote: Quote) {
    let index = this.quotes.findIndex((item: Quote) => item.id === quote.id)
    if (index >= 0) {
      this.$set(this.quotes, index, quote)
    }
  }

  private addNewQuote (quote: Quote) {
    this.quotes.push(quote)
    this.isShowModal = false
  }

  private async getQuites () {
    const { data }: AxiosResponse<any[]> = await api.get('/quotes')
    this.quotes =  Object.values(data)
  }

  private deleteQuote (id: number) {
    const startIndex = this.quotes.findIndex(item => item.id === id)
    this.quotes.splice(startIndex, 1)
  }
  
  private mounted () {
    this.getQuites()
  }
}
