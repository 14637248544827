















































import { Component, Vue } from "vue-property-decorator"

// Components
import ReportModal from '@/components/modals/ReportModal.vue'
import ReportCard from './cards/ReportCard.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { Publication } from '@/interfaces/publications'
// Utils
import api from "@/utils/api"
import axios from "axios"

@Component({
  components: {
    ReportCard,
    ReportModal,
    AcceptModal,
  }
})
export default class SectionPublication extends Vue {
  private publications: Publication[] = []
  private form: Publication[] = []

  private addPublication () {
    this.form.push({
      id: null,
      name: '',
      fileName: '',
      fileId: null,
      url: ''
    })
  }

  private sendForm () {
    api.post('/admin/publications', this.form)
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Публикации успешно сохранены' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private async fetchReports () {
    const { data } = await api.get('/admin/publications')
    this.publications = Object.values(data)
  }

  private addNewReport (report: any) {
    this.publications.push(report)
  }

  private deletePublication (id: number) {
    const modal: any = this.$refs.acceptModal
    modal.showAcceptModal()
      .then(() => {
        const index = this.form.findIndex((item: any) => item.id === id)
        this.form.splice(index, 1)
      })
  }

  private changeFile (file: any, index: number) {
    let baseUrl
    if (process.env.NODE_ENV === 'development') {
      baseUrl = "https://et-api-dev.er.ru/api"
    } else {
      baseUrl = "https://et-api.er.ru/api"
    }
    const formData = new FormData()
    formData.append('file', file)
    axios.post(`${baseUrl}/admin/publications/file`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${localStorage.getItem(`auth-token`)}` }})
      .then((response: any) => {
        this.$set(this.form[index], 'fileName', file.name)
        this.$set(this.form[index], 'fileId', response.data.id)
      })
  }

  private async mounted () {
    await this.fetchReports()
    this.form = this.publications.map((publication: any) => {
      return {
        id: publication.id,
        name: publication.name,
        fileId: publication.file.id,
        fileName: publication.file.name,
        url: publication.file.url,
      }
    })
  }
}
