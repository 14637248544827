import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

@Module({
  dynamic: true,
  name: 'system',
  store
})
class System extends VuexModule {
  isShowSnackbar = false
  textSnackbar = ''

  @Mutation
  setIsShowSnackbar(payload: any) {
    this.isShowSnackbar = payload.isShowSnackbar
    this.textSnackbar = payload.text
  }
}

const SystemModule = getModule(System)

export default SystemModule
