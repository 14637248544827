



























































import { Component, Prop, Vue } from "vue-property-decorator"
// Components
import QuoteModal from '@/components/modals/QuoteModal.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { Quote } from '@/interfaces/quote'
// Utils
import api from "@/utils/api"

@Component({
  components: {
    QuoteModal,
    AcceptModal
  }
})
export default class QuoteCard extends Vue {
  @Prop({ default: null })
  private quote!: Quote

  private isShowModal = false

  private deleteQuote() {
    const modal: any = this.$refs.acceptModal
    modal.showAcceptModal()
      .then(() => {
        api.delete(`admin/quotes/${this.quote.id}`)
          .then(() => {
            SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Цитата успешно удалена' })
          })
          .catch((error) => {
            SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
          })
        this.$emit('deleteQuote', this.quote.id)
      })
  }
}
