











































import { Component, Ref, Vue } from "vue-property-decorator"

// Components
import DatePicker from '@/components/DatePicker.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { Chronology } from '@/interfaces/chronology'
// Utils
import api from "@/utils/api"

@Component({
  components: {
    DatePicker,
    AcceptModal,
  },
})
export default class SectionChronology extends Vue {
  @Ref('acceptModal') readonly acceptModal!: AcceptModal

  private form: Chronology[] = [
    { text: '', date: '', showYear: false }
  ]

  private addNewChronology () {
    this.form.push({ id: null, text: '',  date: undefined, showYear: false })
  }

  private deleteChronology (index: number) {
    this.acceptModal.showAcceptModal()
      .then(() => {
        this.form.splice(index, 1)
      })
  }

  private async getChronologies () {
    const { data } = await api.get('/admin/chronologies')
    this.form = Object.values(data)
  }

  private saveChronology () {
    api.post('/admin/chronologies', this.form)
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Общее количество успешно сохранено' })
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Хронология успешно сохранена' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private mounted () {
    this.getChronologies()
  }
}
