


















import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class AcceptModal extends Vue {
  @Prop({ default: 'Вы действительно хотите удалить?' })
  private title!: string

  @Prop({ default: '' })
  private text!: string

  @Prop({ default: 'Удалить' })
  private acceptButtonText!: string

  @Prop({ default: 'Отменить' })
  private cancelButtonText!: string

  private isShowModalAccept = false

  // eslint-disable-next-line
  private onYesClick(): any {}

  // eslint-disable-next-line
  private onNoClick(): any {}

  public async showAcceptModal(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.isShowModalAccept = true

      this.onYesClick = () => {
        this.isShowModalAccept = false
        resolve(null)
      }

      this.onNoClick = () => {
        this.isShowModalAccept = false
        reject()
      }
    })
  }
}
