














































































import { Component, Vue, Watch } from "vue-property-decorator"

// Components
import AcceptModal from '@/components/modals/AcceptModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { StatisticForm } from '@/interfaces/statistic'
import { District } from '@/interfaces'
// Utils
import api from '@/utils/api'

@Component({
  components: {
    AcceptModal
  }
})
export default class SectionDistrict extends Vue {
  private form: StatisticForm = {
    regionId: null,
    cards: [
      { count: null, name: '' }
    ]
  }

  private districts: District[] = []

  private totalCount = 0

  private countAppealsProcessed = 0

  private async fetchFields() {
    const { data } = await api.get('admin/fields', { params: { regionId: this.form.regionId } })
    this.totalCount = data.countAppealsAll
    this.countAppealsProcessed = data.countAppealsProcessed
  }

  private saveTotalCount() {
    api.put('admin/fields', {
      countAppealsProcessed: this.countAppealsProcessed,
      countAppealsAll: this.totalCount,
      regionId: this.form.regionId
    })
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Общее количество успешно сохранено' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private async fetchDistricts() {
    const { data } = await api.get('districts')
    this.districts = Object.values(data)
    this.districts.unshift({ id: null, name: 'Все федеральные округа' })
  }

  private deleteStatistic(id: number, index: number) {
    const modal: any = this.$refs.acceptModal
    modal.showAcceptModal()
      .then(() => {
        let startIndex = -1
        if (id) {
          startIndex = this.form.cards.findIndex(item => item.id === id)
        }
        this.form.cards.splice(startIndex !== -1 ? startIndex : index, 1)
      })
  }

  private addNorm() {
    this.form.cards.push({ count: null, name: '', id: null })
  }
  
  private save () {
    api.post('admin/statistics', this.form)
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Статистика успешно сохранена' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private async fetchStatistics() {
    const { data } = await api.get('admin/statistics', { params: { regionId: this.form.regionId } })
    this.form.cards = Object.values(data)
  }

  private mounted() {
    this.fetchStatistics()
    this.fetchDistricts()
    this.fetchFields()
  }

  @Watch('form.regionId')
  private changeRegion() {
    this.fetchStatistics()
    this.fetchFields()
  }
}
