































































import { Component, Vue } from "vue-property-decorator"

// Components
import AcceptModal from '@/components/modals/AcceptModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { Norms } from '@/interfaces/norms'
// Utils
import api from '@/utils/api'

@Component({
  components: {
    AcceptModal
  }
})
export default class SectionNorms extends Vue {
  private form: Norms[] = [
    { name: '' }
  ]

  private async getNorms () {
    const { data } = await api.get('/admin/standards')
    this.form = Object.values(data)
  }

  private addNewNorm () {
    this.form.push({ id: null, name: '' })
  }

  private titleEthicalStandards = ''

  private saveTitle () {
    api.put('/admin/fields/', { titleEthicalStandards: this.titleEthicalStandards })
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Заголовок этических норм успешно сохранен' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private async getTitle () {
    const { data } = await api.get('/admin/fields')
    this.titleEthicalStandards = data.titleEthicalStandards
  }

  private deleteNorm (id: number, index: number) {
    const modal: any = this.$refs.acceptModal
    modal.showAcceptModal()
      .then(() => {
        const startIndex = this.form.findIndex(item => item.id === id)
        this.form.splice(startIndex ? startIndex : index, 1)
      })
  }

  private saveNorms () {
    api.post('/admin/standards', this.form)
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Список этических норм успешно сохранен' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private mounted () {
    this.getNorms()
    this.getTitle()
  }
}
