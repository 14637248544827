
















import { Component, Prop, Vue } from "vue-property-decorator"

// Store
import AuthModule from "@/store/modules/auth"

@Component
export default class ConfirmLogoutModal extends Vue {
  @Prop({ required: true })
  readonly isShow !: boolean

  private logout () {
    AuthModule.logout()
    if (localStorage.getItem('auth-token')) {
      localStorage.removeItem('auth-token')
    }
    this.$emit('close')
    this.$router.push('/auth')
  }
}
