

































import { Component, Prop, Vue } from "vue-property-decorator"
import axios from "axios"

interface Quote {
  id?: number,
  fio:  string,
  position: string,
  text: string,
  photo: File | null,
}

@Component
export default class QuoteModal extends Vue {
  @Prop({ default: 'create' })
  private type!: string

  @Prop({ default: null })
  private quote!: Quote | null

  private form: any = {
    fio: '',
    position: '',
    text: '',
    photo: null
  }

  private mounted() {
    if (this.quote) {
      this.form = {
        ...this.quote,
        photo: null
      }
    }
  }

  private sendForm() {
    let baseUrl
    if (process.env.NODE_ENV === 'development') {
      baseUrl = "https://et-api-dev.er.ru/api"
    } else {
      baseUrl = "https://et-api.er.ru/api"
    }

    const url = this.type === 'create' ? '/admin/quotes' : `/admin/quotes/${this.quote?.id}`
    const formData = new FormData()

    Object.keys(this.form).forEach((key: any) => {
      if (this.form[key] !== null) {
        formData.append(key, this.form[key])
      }
    })

    axios.post(`${baseUrl}${url}`, formData, 
      { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${localStorage.getItem(`auth-token`)}` }}
    )
      .then((response) => {
        this.type === 'create' ? this.$emit('addQuote', response.data) : this.$emit('editQuote', response.data)
        this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: 'Цитата успешно сохранена' })
      })
      .catch((error) => {
        this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: error.response.data.message })
      })

  }

}
