





















import { Component, Vue } from "vue-property-decorator"

// Components
import ReportModal from '@/components/modals/ReportModal.vue'
import ReportCard from './cards/ReportCard.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { Socials } from '@/interfaces/socials'
// Utils
import api from "@/utils/api"

@Component({
  components: {
    ReportCard,
    ReportModal,
    AcceptModal,
  }
})
export default class SectionSocials extends Vue {
  private socials: Socials[] = []

  private addSocials () {
    this.socials.push({
      id: null,
      type: '',
      url: ''
    })
  }

  private sendForm () {
    api.put('/admin/socials', this.socials)
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Социальные сети успешно сохранены' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private async fetchSocials () {
    const { data } = await api.get('/admin/socials')
    this.socials = data
  }

  private async mounted () {
    await this.fetchSocials()
  }
}
