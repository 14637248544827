




































































import { Component, Vue } from "vue-property-decorator"
import { MetaInfo } from 'vue-meta'

// Component
import ConfirmLogoutModal from '@/components/modals/ConfirmLogoutModal.vue'
// Store
import AuthModule from "@/store/modules/auth"
import SystemModule from "@/store/modules/system"

@Component({
  components: {
    ConfirmLogoutModal,
  }
})
export default class App extends Vue {
  private items = [
    { name: 'Главная', route: '/home' },
    { name: 'Обращения', route: '/appeal' },
    { name: 'Статусы', route: '/statuses' }
  ]

  private tabs: any[] = [
    { name: 'Карта', value: 'map', to: 'map' },
    { name: 'Статистика', value: 'info', to: 'district' },
    { name: 'Этические нормы', value: 'norms', to: 'norms' },
    { name: 'Хронология', value: 'chronology', to: 'chronology' },
    { name: 'Цитаты', value: 'quote', to: 'quote' },
    { name: 'Отчеты', value: 'report', to: 'report' },
    { name: 'Публикации', value: 'nav', to: 'publications' },
    { name: 'Соц. сети', value: 'social', to: 'socials' },
  ]

  private isShowModal = false

  private changeShowSnackbar () {
    SystemModule.setIsShowSnackbar({ isShowSnackbar: false, text: '' })
  }

  private handleLogout () {
    this.isShowModal = true
  }

  private get isShowSnackbar () {
    return SystemModule.isShowSnackbar
  }

  private get textSnackbar () {
    return SystemModule.textSnackbar
  }

  private get user () {
    return AuthModule.user
  }

  private get token () {
    return AuthModule.token
  }

  private get isLogin () {
    return AuthModule.isLogin
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Комиссия по этике - административная панель',
    }
  }
}
