




























































import { Component, Prop, Vue } from "vue-property-decorator"
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

// Utils
import api from "@/utils/api"
import axios from "axios"

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Обязательно для заполнения',
})

interface Report {
  id?: number | null,
  year: number | null,
  fileId: number | null,
  halfs: any,
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class ReportModal extends Vue {
  @Prop({ default: 'create' })
  private type!: string

  @Prop({ default: null })
  private report!: Report | null

  private fileInvalid = false

  private form: Report = {
    id: null,
    year: null,
    fileId: null,
    halfs: [
      null,
      null
    ],
  }

  private async addFile (file: any, index: any) {
    let baseUrl
    if (process.env.NODE_ENV === 'development') {
      baseUrl = "https://et-api-dev.er.ru/api"
    } else {
      baseUrl = "https://et-api.er.ru/api"
    }
    const formData = new FormData()
    formData.append('file', file)
    axios.post(`${baseUrl}/admin/reports/file`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${localStorage.getItem(`auth-token`)}` }})
      .then((res: any) => {
        this.$set(this.form.halfs, index, res.data.id)
      })

  }

  private async addCommonFile (file: any, model: any) {
    let baseUrl
    if (process.env.NODE_ENV === 'development') {
      baseUrl = "https://et-api-dev.er.ru/api"
    } else {
      baseUrl = "https://et-api.er.ru/api"
    }

    const formData = new FormData()
    formData.append('file', file)
    axios.post(`${baseUrl}/admin/reports/file`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${localStorage.getItem(`auth-token`)}` }})
      .then((res: any) => {
        this.$set(this.form, model, res.data.id)
        this.form.fileId = res.data.id
      })
  }

  private closeModal () {
    this.clearForm()
    this.$emit("close")
  }

  private clearForm () {
    this.form = {
      id: 0,
      year: 0,
      fileId: 0,
      halfs: [
        0
      ]
    }
  }

  private sendForm () {
    const url = this.type === 'create' ? 'admin/reports' : `admin/reports/${this.report?.id}`

    if (this.type === 'create') {
      api.post(url, this.form)
        .then((response) => {
          this.$emit('addReport', response.data)
          this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: 'Отчет успешно добавлен' })
        })
        .catch((error) => {
          this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: error.response.data.message })
        })
    } else {
      api.put(url, this.form)
        .then((response) => {
          this.$emit('editReport', response.data)
          this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: 'Отчет успешно сохранен' })
        })
        .catch((error) => {
          this.$store.commit('setIsShowSnackbar', { isShowSnackbar: true, text: error.response.data.message })
        })
    }
  }

  private get years () {
    const years = []
    const currentYear = new Date().getFullYear()

    for (let i = currentYear; i >= 2000; i--) {
      years.push(i)
    }
    return years
  }

  private mounted () {
    if (this.report) {
      this.form.id = this.report.id
      this.form.year = this.report.year
      this.form.halfs[0] = this.report.halfs[0].id
      this.form.halfs[1] = this.report.halfs[1].id
    }
  }
}
