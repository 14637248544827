







































import { Component, Vue } from "vue-property-decorator"

import AuthModule from "@/store/modules/auth"
import { MetaInfo } from "vue-meta"
import SystemModule from "@/store/modules/system"

@Component
export default class Auth extends Vue {
  private form = {
    name: '',
    password: '',
  }

  private async login() {
    AuthModule.login(this.form)
      .then((res) => {
        localStorage.setItem('auth-token', res.token)
        AuthModule.fetchUser().then(() => {
          this.$router.push({ name: 'Home' })
        })
      })
      .catch(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Проверьте корректность введенных данных' })
      })
  }

  private logout() {
    AuthModule.logout()
  }

  private mounted () {
    AuthModule.fetchUser()
      .then(() => {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect as string)
        }
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Комиссия по этике - административная панель',
    }
  }
}
