import axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'

// eslint-disable-next-line
const camelCaseKeys = require('camelcase-keys')
// eslint-disable-next-line
const snakeCaseKeys = require('snakecase-keys')

const api = axios.create({
  paramsSerializer (params) {
    return qs.stringify(params, {
      arrayFormat: 'indices',
      encode: true
    })
  }
})

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {

    if (process.env.NODE_ENV === 'development') {
      const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME || 'auth-token')

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    if (process.env.NODE_ENV === 'production') {
      config.withCredentials = true
    }

    if (config.data) {
      config.data = snakeCaseKeys(config.data)
    }

    if (config.params) {
      config.params = snakeCaseKeys(config.params)
    }

    config.baseURL = process.env.VUE_APP_BACKEND_API_ENDPOINT

    return config
  },
  reason => {
    return Promise.reject(reason)
  }
)

api.interceptors.response.use(
  response => {
    if (response.data) {
      response.data = camelCaseKeys(response.data, { deep: true })
    }

    return response
  },
  reason => {
    return Promise.reject(reason)
  }
)

export default api
