














import { Component, Vue } from "vue-property-decorator"

// Store
import SystemModule from "@/store/modules/system"
// Utils
import api from '@/utils/api'

@Component
export default class SectionMap extends Vue {
  private form = {
    titleEthicalStandards: '',
    description: '',
    commissionLink: '',
  }

  private async fetchInfo () {
    const { data } = await api.get('/admin/fields')
    this.form = Object.assign({}, this.form, data)
  }

  private save () {
    api.put('/admin/fields', this.form)
      .then(() => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Описание успешно сохранено' })
      })
      .catch((error) => {
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
      })
  }

  private mounted () {
    this.fetchInfo()
  }
}
