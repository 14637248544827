


























































































































import { Component, Ref, Vue } from "vue-property-decorator"
import api from "@/utils/api"

// Types
import { AppealPage, AppealStatus } from '@/interfaces/appeal'

// Store
import SystemModule from "@/store/modules/system"

// Components
import AppealCard from '@/components/AppealCard.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'

@Component({
  components: {
    AppealCard,
    AcceptModal,
  },
})
export default class Appeal extends Vue {
  @Ref('acceptModal') readonly acceptModal!: AcceptModal

  private appeal: AppealPage | null = null
  private statuses: AppealStatus[] = []
  private statistics = []
  private selectedStatistic = []

  // private innerStatus = ''
  // private innerStatusLast = ''

  private mounted() {
    this.getStatuses()
    this.fetchAppeal()
    this.fetchStatistics()
  }

  private async getStatuses() {
    const { data } = await api.get('/admin/statuses')
    this.statuses = Object.values(data)
  }

  private async rejectAppeal() {
    const { data } = await api.patch(`/admin/appeals/${this.$route.params.id}/cancel`)
    if (this.appeal) {
      SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Обращение успешно отклонено' })
      this.$set(this.appeal, 'status', data.status)
    }
  }

  private async fetchStatistics() {
    const { data } = await api.get('admin/statistics')
    this.statistics = Object.values(data)
  }

  private async applyStatus() {
    const { data } = await api.post(`/admin/appeals/${this.$route.params.id}/work`)
    if (this.appeal) {
      SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Обращение успешно подтверждено' })
      this.$set(this.appeal, 'status', data.status)
    }
  }

  private saveStatistic() {
    api.patch(`/admin/appeals/${this.$route.params.id}/statistic`, this.selectedStatistic)
  }

  private async fetchAppeal() {
    const { data } = await api.get(`/admin/appeals/${this.$route.params.id}`)
    this.appeal = data
    // this.innerStatus = this.innerStatusLast = this.getStatusValue(data.status)
    this.selectedStatistic = data.statistics || []
  }

  // private getStatusValue (name: string) {
  //   return this.statuses.find((item) => item.name === name)?.slug ?? ''
  // }

  // private getStatusName (value: string) {
  //   return this.statuses.find((item) => item.slug === value)?.name ?? ''
  // }

  // private handleChangeStatus (value: string) {
  //   this.acceptModal.showAcceptModal()
  //     .then(async () => {
  //       if (this.appeal) {
  //         const { data } = await api.patch(
  //           `admin/appeals/${this.appeal.id}/change-status`,
  //           { status: value },
  //         )

  //         this.innerStatusLast = value
  //         SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Статус обращения успешно изменён' })
  //         this.fetchAppeal()
  //       }
  //     })
  //     .catch(() => {
  //       this.innerStatus = this.innerStatusLast
  //     })
  // }
}
