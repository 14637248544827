import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router"
import Home from "../views/Home.vue"
import Auth from '../views/Auth.vue'
import Appeal from '../views/Appeal.vue'
import Appeals from '../views/Appeals.vue'
import Statuses from '../views/Statuses.vue'
import SectionChronology from '@/components/home-sections/SectionChronology.vue'
import SectionDistrict from '@/components/home-sections/SectionDistrict.vue'
import SectionMap from '@/components/home-sections/SectionMap.vue'
import SectionPublications from '@/components/home-sections/SectionPublications.vue'
import SectionNorms from '@/components/home-sections/SectionNorms.vue'
import SectionsQuote from '@/components/home-sections/SectionsQuote.vue'
import SectionReport from '@/components/home-sections/SectionReport.vue'
import SectionSocials from '@/components/home-sections/SectionSocials.vue'
import middlewarePipeline from './middleware/pipeline'
import auth from "./middleware/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    redirect: '/home/map',
    children: [
      {
        path: "chronology",
        name: "chronology",
        component: SectionChronology,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
      {
        path: "district",
        name: "district",
        component: SectionDistrict,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
      {
        path: "map",
        name: "map",
        component: SectionMap,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
      {
        path: "publications",
        name: "publications",
        component: SectionPublications,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
      {
        path: "norms",
        name: "norms",
        component: SectionNorms,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
      {
        path: "quote",
        name: "quote",
        component: SectionsQuote,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
      {
        path: "report",
        name: "report",
        component: SectionReport,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
      {
        path: "socials",
        name: "socials",
        component: SectionSocials,
        meta: {
          parent: 'home',
          middleware: [auth]
        }
      },
    ]
  },
  {
    path: "/statuses",
    name: "statuses",
    component: Statuses,
    meta: { middleware: [auth] }
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/appeal/:id",
    name: "Appeal",
    component: Appeal,
    meta: { middleware: [auth] }
  },
  {
    path: "/appeal",
    name: "Appeals",
    component: Appeals,
    meta: { middleware: [auth] }
  },
  {
    path: '/',
    redirect: '/home'
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = { from, next, to }

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  })
})

export default router;
