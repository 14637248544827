























































import { AppealInfo, AppealStatus } from "@/interfaces/appeal"
import { Component, Prop, Ref, Vue } from "vue-property-decorator"
import api from "@/utils/api"

// Components
import AcceptModal from '@/components/modals/AcceptModal.vue'
import SystemModule from "@/store/modules/system"

@Component({
  components: {
    AcceptModal,
  },
})
export default class AppealCard extends Vue {
  @Ref('acceptModal') readonly acceptModal!: AcceptModal

  @Prop({ default: null })
  private appeal!: AppealInfo

  @Prop({ default: () => [] })
  private statuses!: AppealStatus[]

  private innerStatus = ''
  private innerStatusLast = ''

  private mounted () {
    this.$nextTick(() => {
      this.innerStatus = this.innerStatusLast = this.getStatusValue(this.appeal.status)
    })
  }

  private getStatusValue (name: string) {
    return this.statuses.find((item) => item.name === name)?.slug ?? ''
  }

  private getStatusName (value: string) {
    return this.statuses.find((item) => item.slug === value)?.name ?? ''
  }

  private handleChangeStatus (value: string) {
    this.acceptModal.showAcceptModal()
      .then(async () => {
        const { data } = await api.patch(
          `admin/appeals/${this.appeal.id}/change-status`,
          {
            status: value,
          },
        )

        this.innerStatusLast = value
        SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Статус обращения успешно изменён' })
      })
      .catch(() => {
        this.innerStatus = this.innerStatusLast
      })
  }
}
