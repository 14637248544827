



































































import api from "@/utils/api"
import AppealCard from '@/components/AppealCard.vue'
import DatePicker from '@/components/DatePicker.vue'
import { Component, Vue, Watch } from "vue-property-decorator"
import { IPaginate } from "@/interfaces"
import { AppealInfo } from '@/interfaces/appeal'
import axios from "axios"

@Component({
  components: {
    AppealCard,
    DatePicker
  }
})
export default class Appeal extends Vue {
  private appeals: IPaginate<AppealInfo>  = {
    data: [],
    meta: {
      currentPage: 1,
      lastPage: 1,
    }
  }

  private dateStart = null
  private dateEnd = null
  private district = null

  private statuses = []

  private districts = []

  private page = 1

  private filter = {
    lastname: '',
    status: '',
    emptyStatistic: false,
  }

  private loadMore () {
    this.page++
    this.fetchAppeals()
  }

  private async fetchStatuses () {
    const { data } = await api.get('/statuses')
    this.statuses = Object.values(data)
  }

  private async fetchDistricts () {
    const { data } = await api.get('/districts')
    this.districts = Object.values(data)
  }

  private applyFilter () {
    this.page = 1
    this.fetchAppeals()
  }

  private clearFilter () {
    this.filter = {
      lastname: '',
      status: '',
      emptyStatistic: false,
    }
    this.dateStart = null
    this.dateEnd = null
    this.district = null
    this.fetchAppeals()
  }

  private async fetchAppeals () {
    const { data }: any = await api.get('/admin/appeals',
      {
        params: {
          page: this.page,
          lastname: this.filter.lastname,
          empty_statistic: this.filter.emptyStatistic ? 1 : 0,
          status: this.filter.status,
          date_start: this.dateStart,
          date_end: this.dateEnd,
          region_id: this.district,
        }
      }
    )

    if (this.page === 1) {
      this.appeals.data = data.data
      this.appeals.meta = data.meta
    } else {
      this.$set(this.appeals, 'data', this.appeals.data.concat(data.data))
      this.appeals.meta = data.meta
    }
  }

  private async download () {
    let baseUrl
    if (process.env.NODE_ENV === 'development') {
      baseUrl = "https://et-api-dev.er.ru/api"
    } else {
      baseUrl = "https://et-api.er.ru/api"
    }
    await axios(
      {
        url: `${baseUrl}/admin/appeals/report`,
        responseType: 'blob',
        method: 'GET',
        headers: { 'response-type': 'blob', 'Authorization': `Bearer ${localStorage.getItem(`auth-token`)}` },
        params: {
          lastname: this.filter.lastname,
          empty_statistic: this.filter.emptyStatistic ? 1 : 0,
          status: this.filter.status,
          date_start: this.dateStart,
          date_end: this.dateEnd,
          region_id: this.district,
        }
      })
      .then(res => {
        const fileURL = URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'otchet.xlsx')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
  }

  private mounted () {
    this.fetchAppeals()
    this.fetchStatuses()
    this.fetchDistricts()
  }

  @Watch('filter', { deep: true })
  private watchFilter () {
    this.applyFilter()
  }

  @Watch('dateStart')
  private watchDateStart () {
    this.applyFilter()
  }

  @Watch('dateEnd')
  private watchDateEnd () {
    this.applyFilter()
  }

  @Watch('district')
  private watchDistrict () {
    this.applyFilter()
  }
}
