






































import { Component, Vue } from "vue-property-decorator"

// Components
import ReportCard from './cards/ReportCard.vue'
import ReportModal from '@/components/modals/ReportModal.vue'
// Types
import { Report } from '@/interfaces/reports'
// Utils
import api from "@/utils/api"

@Component({
  components: {
    ReportCard,
    ReportModal,
  }
})
export default class SectionNavigation extends Vue {
  private isShowModal = false
  
  private reports: Report[] = []

  private editReport (value: Report) {
    let index = this.reports.findIndex(item => item.id === value.id)

    if (index >= 0) {
      this.$set(this.reports, index, value)
    }
    this.isShowModal = false
  }

  private deleteReport (id: number) {
    const index = this.reports.findIndex(item => item.id === id)
    if (index >= 0) {
      this.reports.splice(index, 1)
    }
  }

  private async fetchReports () {
    const { data } = await api.get('/admin/reports')
    this.reports = Object.values(data)
  }

  private addReport (report: Report) {
    this.reports.push(report)
    this.isShowModal = false
  }

  private get years () {
    const years = []

    for (let i = new Date().getFullYear(); i >= 2000; i--) {
      years.push(i)
    }
    return years
  }

  private mounted () {
    this.fetchReports()
  }
}
