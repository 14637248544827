import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import api from '@/utils/api'

@Module({
  dynamic: true,
  name: 'auth',
  store
})
class Auth extends VuexModule {
  user: any = null
  token = localStorage.getItem('auth-token') as string
  isLogin = false

  @Mutation
  setToken(payload: string) {
    this.token = payload
  }

  @Mutation
  setUser(payload: any) {
    this.user = payload
  }

  @Mutation
  setIsLogin(payload: boolean) {
    this.isLogin = payload
  }

  @Action({ rawError: true })
  async login (form: any) {
    const { data } = await api.post('/admin/login', form)
    this.setUser(data.user)
    this.setToken(data.token)
    this.setIsLogin(true)

    return data
  }

  @Action({ rawError: true })
  async logout () {
    await api.post('/admin/logout').then(() => {
      this.setUser(null)
      this.setToken('')
      this.setIsLogin(false)
    })
  }

  @Action({ rawError: true })
  async checkToken () {
    try {
      const { data } = await api.get('/admin/checkToken')
      this.setIsLogin(true)
      this.setToken(data.token)

      return data
    } catch {
      this.logout()
    }
  }

  @Action({ rawError: true })
  async fetchUser () {
    const { data } = await api.get('/admin/user')
    this.setUser(data)
    
    return data
  }
}

const AuthModule = getModule(Auth)

export default AuthModule
