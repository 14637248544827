



























































import { Component, Prop, Vue } from "vue-property-decorator"

// Components
import ReportModal from '@/components/modals/ReportModal.vue'
import AcceptModal from '@/components/modals/AcceptModal.vue'
// Store
import SystemModule from "@/store/modules/system"
// Types
import { Report } from '@/interfaces/reports'
// Utils
import api from "@/utils/api"

@Component({
  components: {
    ReportModal,
    AcceptModal
  }
})
export default class ReportCard extends Vue {
  @Prop({ default: null })
  private report!: Report

  private isShowModal = false

  private addReport (value: Report) {
    this.isShowModal = false
    this.$emit('addReport', value)
  }

  private editReport (value: Report) {
    this.isShowModal = false
    this.$emit('editReport', value)
  }

  private deleteReport () {
    const modal: any = this.$refs.acceptModal
    modal.showAcceptModal()
      .then(() => {
        api.delete(`admin/reports/${this.report.id}`)
          .then(() => {
            this.$emit('deleteReport', this.report.id)
            SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: 'Отчет успешно удален' })
          })
          .catch((error) => {
            SystemModule.setIsShowSnackbar({ isShowSnackbar: true, text: error.response.data.message })
          })
      })
  }
}
