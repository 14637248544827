















import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import moment from 'moment'

@Component
export default class DatePicker extends Vue {
  @Prop({ default: null })
  value!: any

  @Prop({ default: 'Дата' })
  label!: string

  private dateFormatted: any = null
  private menu = false

  private mounted() {
    this.dateFormatted = this.formatDate(this.actualDate)
  }

  private get actualDate () {
    if (!this.value) return null
    return moment(this.value, 'DD.MM.YYYY').format()
  }

  private set actualDate (value) {
    this.$emit('input', moment(value).format('DD.MM.YYYY'))
  }

  @Watch('value')
  private changeDate() {
    this.dateFormatted = this.value
  }

  private formatDate (date: any) {
    if (!date) return null

    return moment(date).format('DD.MM.YYYY')
  }

}
